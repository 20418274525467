const remoteUtils = await import( 'AAPE_Sync/utilities' );

const site = "b2consumer-mobile";
const client = "homedepotmobile";
const publishPath = "prod";


let toggleSwitches = {};

let getPublishPath = function () {

	return publishPath;

};


let getClient = function () {

	return client;

};


let mobileToggleSwitches = {
    "betaMode": {
        "enabled": ( function () {
            return ( document.cookie.indexOf( 'HD_DC=beta' ) > -1 );
        }() )
    },
    "perimeterx": {
        "enabled": true
    },
    "impressionProvider": {
        "enabled": true
    },
    "ecridsync": {
        "enabled": true
    }
};

let desktopToggleSwitches = {
    "betaMode": {
        "enabled": ( function () {
            return ( document.cookie.indexOf( 'HD_DC=beta' ) > -1 );
        }() )

    },
    "perimeterx": {
        "enabled": true
    },
    "impressionProvider": {
        "enabled": true
    },
    "ecridsync": {
        "enabled": true
    }
};

/**
 * Determines the beacon delay based on the page name
 * @param pgNm {string}: The page name to evaluate
 * @returns {number}: the delay in milliseconds to use for the given page name
 */

let getPgBeaconDelay = function ( pgNm ) {

    pgNm = ( pgNm ? pgNm.toLowerCase() : "" );

    if ( site === "b2consumer-mobile" || site === "b2business-mobile" ) {
        return 1500; // default
    }
    else if ( site === "b2consumer-desktop" || site === "b2business-desktop" ) {
        if ( pgNm === "list details" || pgNm === "my list details" ) {
            return 1500;
        }
        else {
            return ( remoteUtils.isPIP() ? 2000 : 0 );
        }
    }

    return 0;

};

/**
 * reads the toggles and sets the feature accordingly.
 */
( function () {

    if ( site === "b2consumer-desktop" || site === "b2business-desktop" ) {
        toggleSwitches = desktopToggleSwitches;
    }
    else {
        toggleSwitches = mobileToggleSwitches;
    }

}() );

/**
 * Check if feature enabled
 * @param {string} featureName
 * @param {object} [toggles] -json object
 * @returns {boolean}
 */
let featureEnabled = function ( featureName, toggles ) {

    toggles = toggles ? toggles : toggleSwitches; //todo: enforce camel case naming convention.

    for ( var feature in toggles ) {
        if ( toggles.hasOwnProperty( feature ) ) {
            if ( featureName === feature ) {
                return toggles[ feature ].enabled;
            }
        }
    }
    return false;
};

const getReportSuiteOverrides = function( businessType ) {

    let isMilitaryExchange = ( location.hostname.indexOf('exchange.homedepot.com' ) !== -1 || location.hostname.indexOf('storefront.homedepot.com' ) !== -1 ? true : false ),
        suitesSet = new Set();

	if ( remoteUtils.isProdDeployment() ) {

		suitesSet.add( "homedepotglobalproduction" );

		if ( businessType.toString().toLowerCase() === "b2b" ) {
			suitesSet.add( "homedepotb2bprod" );
			}

            if ( isMilitaryExchange ) {
				suitesSet.add( "homedepotmilitary" );
			}

	}
	else {

		suitesSet.add( "homewebsdkpocdev" );
		suitesSet.add( "homedepotglobaldev" );

		if ( businessType.toString().toLowerCase() === "b2b" ) {
			suitesSet.add( "homedepotb2bdev" );
		}

	}

	let uniqueSuites = Array.from( new Set( suitesSet ) );

	// We want to remove the B2B report suites for Native Apss for now
	// TODO: follow up on if/when this will change
	if ( remoteUtils.isConsumerApp() ) {

		let indexOfRSID = uniqueSuites.indexOf( 'homedepotb2bprod' );
		indexOfRSID = indexOfRSID > -1 ? indexOfRSID : uniqueSuites.indexOf( 'homedepotb2bdev' );

		if ( indexOfRSID > -1 ) {
			uniqueSuites.splice( indexOfRSID, 1 );
		}

	}

	return uniqueSuites;

};


export {
    site,
    getPublishPath,
	getReportSuiteOverrides,
    getClient,
    getPgBeaconDelay,
    featureEnabled
};
